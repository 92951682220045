import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map, tap } from 'rxjs/operators';

import { RoleListItem } from '@mp/kernel/roles/domain';
import { NotificationService, PageResponse } from '@mp/shared/data-access';

import { RolesService } from '../services';

import { RolesActions } from './roles.actions';

@Injectable()
export class RolesEffects {
  fetchRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RolesActions.fetchRoles),
      fetch({
        run: ({ searchTerm }) => {
          return this.rolesService
            .fetchRoles(searchTerm)
            .pipe(
              map((rolesPageResponse: PageResponse<RoleListItem>) =>
                RolesActions.fetchRolesSuccess({ rolesPageResponse }),
              ),
            );
        },
        onError: () => {
          this.notificationService.toastDanger('Fehler beim Laden der Rollen.');
          return RolesActions.fetchRolesError();
        },
      }),
    );
  });

  deleteRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RolesActions.deleteRole),
      fetch({
        run: ({ roleId }) => {
          return this.rolesService.deleteRole(roleId).pipe(
            tap(() => this.notificationService.toastSuccess('Rolle erfolgreich gelöscht.')),
            map(() => RolesActions.deleteRoleSuccess({ roleId })),
          );
        },
        onError: () => {
          this.notificationService.toastDanger('Fehler beim Löschen der Rolle.');
          return RolesActions.deleteRoleError();
        },
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly rolesService: RolesService,
    private readonly notificationService: NotificationService,
  ) {}
}
