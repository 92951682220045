import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { QueryParams } from '@core/shared/util';
import { RoleListItem } from '@mp/kernel/roles/domain';
import { PageResponse } from '@mp/shared/data-access';

@Injectable({ providedIn: 'root' })
export class RolesService {
  private readonly baseUrl = '/api/m/kernel/roles';

  constructor(private readonly http: HttpClient) {}

  fetchRoles(searchTerm?: string): Observable<PageResponse<RoleListItem>> {
    const params = QueryParams.build().param('searchTerm', searchTerm).withoutPagination().toHttpParams();

    return this.http.get<PageResponse<RoleListItem>>(this.baseUrl, { params });
  }

  deleteRole(roleId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${roleId}`);
  }
}
